
  import { Vue, Component } from 'vue-property-decorator'
  import { MaterialInterface, MaterialType, FileResultInterface } from '@/interface/BaseInterface'
  import { size, trim } from 'lodash'

  @Component
  export default class CreateMaterial extends Vue {
    data: MaterialInterface = { fileUrl: '', type: 'audio', settings: {} }

    result: FileResultInterface = {
      path: '',
      filename: ''
    }

    materialTypes: MaterialType[] = ['file', 'video', 'audio', 'image']

    reset() {
      this.data = { fileUrl: '', type: 'audio', settings: {} }
    }

    handleOpen() {
      console.log('open')
    }

    handleSuccess(data: FileResultInterface[]) {
      if (size(data) > 0) {
        this.result = data[0]
        this.data.fileUrl = this.result.path
        this.data.settings.filename = this.result.filename
      }
    }

    handleClose() {
      this.reset()
    }

    async handleSubmit() {
      try {
        if (trim(this.data.fileUrl) !== '') {
          this.$emit('submit', this.data)
        }
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
