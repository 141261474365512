
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { PartInterface } from '@/interface/BaseInterface'
  import QuestionFrom from './QuestionFrom.vue'

  @Component({
    components: { QuestionFrom }
  })
  export default class PartFrom extends Vue {
    @Prop({ required: true, type: Object }) data!: PartInterface

    handleEdit() {
      this.$emit('edit', this.data)
    }

    handleRemove() {
      this.$emit('remove', this.data)
    }
  }
