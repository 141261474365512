
  import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
  import { QuizInterface, MaterialInterface } from '@/interface/BaseInterface'
  import BaseEditor from '@/components/base/editor/BaseEditor.vue'
  import CreateMaterial from '../modules/CreateMaterial.vue'
  import { namespace } from 'vuex-class'
  import { SPopupBase } from '@/store/base'
  import { filter, unionBy } from 'lodash'

  const tBase = namespace('base')

  @Component({
    components: { BaseEditor, CreateMaterial }
  })
  export default class QuizFrom extends Vue {
    @Prop({ required: true, type: Object }) data!: QuizInterface
    @tBase.Action('setOpenPopup') setOpenPopup!: (data: SPopupBase) => void

    materials: MaterialInterface[] = this.data.materials || []
    checkbox = false
    startDate = new Date()
    endDate = new Date()

    @Watch('data._id', { immediate: true }) watchId(new_: string) {
      if (new_ !== '') {
        if (this.$refs.editor) {
          //@ts-ignore
          this.$refs.editor.setData(this.data.description)
        }
        this.materials = this.data.materials || []
        this.checkbox = this.data.showResult || false
        this.startDate = this.data.startDate ? new Date(this.data.startDate) : new Date()
        this.endDate = this.data.endDate ? new Date(this.data.endDate) : new Date()
      }
    }

    @Watch('checkbox', { immediate: false }) watchCheckbox(new_: boolean) {
      this.data.showResult = new_
    }

    @Watch('startDate', { immediate: false, deep: true }) watchStartDate(new_: Date) {
      this.data.startDate = new_.getTime()
    }

    @Watch('endDate', { immediate: false, deep: true }) watchEndDate(new_: Date) {
      this.data.endDate = new_.getTime()
    }

    handleSubmit(data: any) {
      this.data.materials = unionBy(this.data.materials, [data], 'fileUrl')
      this.materials = unionBy(this.materials, [data], 'fileUrl')
    }

    handleRemoveMaterial(data: MaterialInterface) {
      this.$forceUpdate()
      this.data.materials = filter(this.data.materials, value => {
        return value.fileUrl !== data.fileUrl
      })
      this.materials = filter(this.data.materials, value => {
        return value.fileUrl !== data.fileUrl
      })
      this.$forceUpdate()
    }

    handleChange(value: string) {
      this.data.description = value
    }
  }
