
  import { Vue, Component } from 'vue-property-decorator'
  import { PartInterface } from '@/interface/BaseInterface'
  import QuizApi from '../../api/quizApi'
  import getApi from '@/api'
  import BaseEditor from '@/components/base/editor/BaseEditor.vue'
  import { assign } from 'lodash'

  const quizApi: QuizApi = getApi('quiz')

  @Component({ components: { BaseEditor } })
  export default class PartCreate extends Vue {
    data: PartInterface = {
      _id: '',
      header: '',
      quizId: '',
      note: ''
    }

    isAdd = true

    reset() {
      this.isAdd = true
      this.data = {
        _id: '',
        header: '',
        quizId: '',
        note: ''
      }
      if (this.$refs.editor) {
        // @ts-ignore
        this.$refs.editor.setData('')
      }
    }

    handleOpen() {
      if (this.$refs.formActive) {
        // @ts-ignore
        this.$refs.formActive.clearValidate()
      }
    }

    setData(data: PartInterface) {
      console.log('set data')
      this.data = assign(this.data, data)
      this.isAdd = false
      if (this.$refs.editor) {
        // @ts-ignore
        this.$refs.editor.setData(data.note)
      }
    }

    handleClose() {
      this.reset()
    }

    handleChange(text: string) {
      this.data.note = text
    }

    async handleSubmit() {
      try {
        // console.log(this.data)
        // @ts-ignore
        this.$refs.formActive.validate(async (value: boolean) => {
          if (value) {
            this.data.quizId = this.quizId

            if (this.data._id === '') {
              const data = await quizApi.addPart(this.quizId, this.data)
              this.$emit('submit', data)
              this.$message.success(this.$t('createSuccess') as string)
            } else {
              const data = await quizApi.updatePart(this.quizId, this.data._id, this.data)
              this.$emit('edit', data)
              this.$message.success(this.$t('editSuccess') as string)
            }
            return Promise.resolve()
          } else {
            return Promise.reject(false)
          }
        })
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }
