
  import { Vue, Component } from 'vue-property-decorator'
  import { QuizInterface, PartInterface } from '@/interface/BaseInterface'
  import QuizFrom from '../../components/quiz/QuizFrom.vue'
  import QuestionFrom from '../../components/quiz/QuestionFrom.vue'
  import getApi from '@/api'
  import QuizApi from '../../api/quizApi'
  import PartFrom from '../../components/quiz/PartFrom.vue'
  import PartCreate from '../../components/quiz/PartCreate.vue'
  import { assign, forEach, filter, findIndex } from 'lodash'
  import { namespace } from 'vuex-class'
  import { SPopupBase } from '@/store/base'

  const quizApi: QuizApi = getApi('quiz')
  const tBase = namespace('base')

  @Component({
    components: { QuizFrom, QuestionFrom, PartFrom, PartCreate }
  })
  export default class QuizEdit extends Vue {
    @tBase.Action('setOpenPopup') setOpenPopup!: (data: SPopupBase) => void

    quiz: QuizInterface = {
      _id: this.quizId,
      name: '',
      courseId: this.courseId,
      sectionId: '',
      description: '',
      timeLimit: 0,
      condition: '',
      gradeToPass: 0,
      attemptAllowed: 0,
      numberPart: 0,
      parts: []
    }

    active = 'detail'

    isLoading = false
    isCreateLoading = false

    get getPoint() {
      let point = 0
      forEach(this.quiz.parts, value => {
        forEach(value.questions, elm => {
          // @ts-ignore
          const a = parseInt(elm.point) || 0
          point += a
        })
      })
      return point
    }

    async init() {
      try {
        this.isLoading = true
        const result = await quizApi.getById(this.quizId)
        const listQuestion = await quizApi.getQuestion(this.quizId)
        this.quiz = assign(this.quiz, result, listQuestion)
        this.quiz.courseId = this.courseId
        this.isLoading = false
      } catch (error) {
        console.log(error)
      }
    }

    handleCancel() {
      this.$router.push({
        name: 'quizDetail',
        params: {
          id: this.courseId,
          quizId: this.quizId
        }
      })
    }

    async handleSave() {
      try {
        this.isCreateLoading = true
        await quizApi.update(this.quiz._id, this.quiz)
        this.isCreateLoading = false
        this.$router.push({
          name: 'quizDetail',
          params: {
            id: this.courseId,
            quizId: this.quizId
          }
        })
      } catch (error) {
        this.isCreateLoading = false
      }
    }

    handlePartEdit(data: PartInterface) {
      this.setOpenPopup({
        isOpen: true,
        module: 'createPart'
      })
      if (this.$refs.partCreate) {
        // @ts-ignore
        this.$refs.partCreate.setData(data)
      }
    }

    async handlePartRemove(data: PartInterface) {
      try {
        await quizApi.removePart(this.quizId, data._id)
        this.quiz.parts = filter(this.quiz.parts, value => {
          return value._id !== data._id
        })
      } catch (error) {
        console.log(error)
      }
    }

    async handleAddPart() {
      const listQuestion = await quizApi.getQuestion(this.quizId)
      this.quiz = assign(this.quiz, listQuestion)
    }

    handeUpdatePart(data: PartInterface) {
      const index = findIndex(this.quiz.parts, value => {
        return value._id === data._id
      })
      if (index !== -1) {
        this.quiz.parts[index] = assign(this.quiz.parts[index], data)
      }
    }

    handleCreate() {
      this.setOpenPopup({
        isOpen: true,
        module: 'createPart'
      })
      if (this.$refs.partCreate) {
        // @ts-ignore
        this.$refs.partCreate.reset()
      }
    }

    created() {
      this.init()
      if (this.$route.name === 'quizAddQuestion') {
        this.active = 'question'
      }
    }
  }
