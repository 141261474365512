
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { QuestionInterface, QuestionType } from '@/interface/BaseInterface'
  import QuestionFromItem from './QuestionFromItem.vue'
  import QuizApi from '../../api/quizApi'
  import getApi from '@/api'
  import { assign, filter, findIndex } from 'lodash'
  import QuestionApi from '@/modules/questions/api/questionApi'

  const quizApi: QuizApi = getApi('quiz')
  const questionApi: QuestionApi = getApi('question')

  @Component({
    components: { QuestionFromItem }
  })
  export default class QuestionFrom extends Vue {
    @Prop({ required: true, type: Array }) data!: QuestionInterface[]
    @Prop({ required: true, type: String }) partId!: string

    isLoading = false

    async handleCreate() {
      try {
        if (this.isLoading) {
          return
        }
        this.isLoading = true
        const result = await quizApi.createQuizQuestion(this.quizId, this.partId)
        this.data.push(
          assign(
            {
              _id: '',
              type: QuestionType.MUTILCHOICE,
              name: 'New Question',
              content: '',
              tags: []
            },
            result,
            {
              _id: result.questionId
            }
          )
        )
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    }

    handleCreateGroup() {
      console.log('123')
    }

    async handleSubmit(data: QuestionInterface) {
      try {
        await questionApi.update(data._id, data)
        const index = findIndex(this.data, value => {
          return value._id === data._id
        })
        if (index !== -1) {
          this.data[index] = assign(this.data[index], data)
        }
      } catch (error) {
        console.log(error)
      }
    }

    async handleRemove(data: QuestionInterface) {
      try {
        await quizApi.deleteQuestion(this.quizId, this.partId, data._id)
        this.data = filter(this.data, value => {
          return value._id !== data._id
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
