
  import { Vue, Component, Prop } from 'vue-property-decorator'
  import { QuestionInterface } from '@/interface/BaseInterface'
  import QuestionCreate from '../../../questions/components/question/QuestionCreate.vue'
  import { assign, cloneDeep } from 'lodash'

  @Component({
    components: { QuestionCreate }
  })
  export default class QuestionFromItem extends Vue {
    @Prop({ required: true, type: Object }) data!: QuestionInterface

    isEdit = false
    isLoading = true

    handleEdit() {
      this.isEdit = true
      if (this.isEdit && this.data._id !== '') {
        this.isLoading = true
        const cloneData = cloneDeep(this.data)
        if (this.$refs.questionCreate) {
          // @ts-ignore
          this.$refs.questionCreate.setDataEdit(cloneData)
        }
        this.isLoading = false
      }
    }

    handleSubmit(data: QuestionInterface) {
      this.isEdit = false
      this.data = assign(this.data, data)
      this.$emit('submit', data)
    }

    handleRemove() {
      this.$emit('remove', this.data)
    }

    handleClose() {
      this.isEdit = false
    }

    mounted() {
      this.data.content = this.data.content || ''
      this.data.answers = this.data.answers || []
      this.data.keys = this.data.keys || []
    }
  }
